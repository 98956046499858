/*----Setting----*/
.settings-page {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: column;

}
    
    textarea{
        width: 100%;
    }
    .setting-card {
        border: 1px solid #ccc;
        border-radius: 5px;
        padding: 20px;
        margin: 10px;
      }
      
      .qr-popup {
        display: flex;
        justify-content: center; /* Center horizontally */
        align-items: center; /* Center vertically */
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        backdrop-filter: blur(8px)
      }
      
      .qr-popup-content {
        background-color: white;
        padding: 20px;
        border-radius: 8px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); /* Add shadow for depth */
      }
      
      .close {
        position: absolute;
        top: 10px;
        right: 10px;
        cursor: pointer;
        font-size: 24px;
        color: white;
      }
      


      .org-table {

        margin: 30px 0;
}
  

.org-container {
  overflow-x: auto; /* Enable horizontal scrolling for small screens */
}
  
  


.create-org{
    box-shadow: var(--neumorphism);
    padding: 40px;
    margin: 20px 0;
    border-radius: 0.7rem;
  

    .create-org-btn{
        margin: 10px 0;
        width: 100%;
    }

    label{
        display: inline;
        margin-right: 10px;
        margin-top: 10px;
    }
    select{
        border-radius: 5px;
        width: 100%;
    }
   
    .select-dropdown,
    .select-dropdown * {
        margin: 0;
        padding: 0;
        position: relative;
        box-sizing: border-box;
    }
    .select-dropdown {
        position: relative;
        background-color: white;
        border-radius: 4px;
        margin: 15px 0;
    }
    .select-dropdown select {
        font-size: 1rem;
        font-weight: normal;
        max-width: 100%;
        padding: 8px 24px 8px 10px;
        border: none;
        background-color: transparent;
            -webkit-appearance: none;
            -moz-appearance: none;
        appearance: none;
    }
    .select-dropdown select:active, .select-dropdown select:focus {
        outline: none;
        box-shadow: none;
    }
    .select-dropdown:after {
        content: "";
        position: absolute;
        top: 50%;
        right: 8px;
        width: 0;
        height: 0;
        margin-top: -2px;
        border-top: 5px solid #aaa;
        border-right: 5px solid transparent;
        border-left: 5px solid transparent;
    }
  }
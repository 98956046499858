/* google font */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;800&family=VT323&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik&display=swap');

/* layout */
:root {
  --primary: #1aac83;
  --primary-dark: #148565;
  --secondary: #ac1a8c;
  --tertiary: #ac3a1a;
  --error: #e7195a;
  --success: #00db6e;
  --neumorphism:  7px 7px 26px #cecece,
  -7px -7px 26px #f2f2f2;
}
body {
  /* max-width: 1400px; */

  background: #e0e0e0;
  margin: auto;
  font-family: 'Rubik', sans-serif;
}
.pages{
  width: clamp(350px, 97%, 1400px);
  margin: 0 auto;
  padding: 20px;

}

a{
  text-decoration: none;

}
a.link{
  
  color: var(--primary) ;
  position: relative;
}
a.link::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -3px;
  width: 0;
  height: 2px;
  background-color: var(--primary);
  transition: width 0.3s ease; /* Add transition for smooth animation */
}
.page-nav{
  background: linear-gradient(145deg, #f0f0f0, #cacaca);
  box-shadow:  20px 20px 60px #bebebe,
               -20px -20px 60px #ffffff;
            width: fit-content;
            padding: 8px;
            border-radius: 0.3rem;
            margin:  0 0 20px;
            
}
a.link:hover::after {
  width: 100%;
  
}

.page-nav .link {
  margin: 0 10px;
  color: var(--secondary);
}
.page-nav .link::after {

  background-color: var(--secondary);
  transition: width 0.3s ease; /* Add transition for smooth animation */
}
@keyframes underlineAnimation {
  from {
    width: 0; /* Start with no underline */
  }
  to {
    width: 100%; /* Extend to full width */
  }
}
header {
  background: #fff;
}
header .container {
  max-width: 1400px;
  margin: 0 auto;
  padding: 10px 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
header a {
  color: #333;
  text-decoration: none;
}

/* homepage */
.home {
  display: grid;
  grid-template-columns: 3fr 1fr;
  gap: 100px;
}
.setting-details {
  background: #fff;
  border-radius: 4px;
  margin: 20px auto;
  padding: 20px;
  position: relative;
  box-shadow: 2px 2px 5px rgba(0,0,0,0.05);
}
.setting-details h4 {
  margin: 0 0 10px 0;
  font-size: 1.2em;
  color: var(--primary);
}
.setting-details p {
  margin: 0;
  font-size: 0.9em;
  color: #555;
}
.setting-details span {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  background: #f1f1f1;
  padding: 6px;
  border-radius: 50%;
  color: #333;
}

/* new setting form */
label, input {
  display: block;
}
input {
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 20px;
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
}
form button {
  background: var(--primary);
  border: 0;
  color: #fff;
  padding: 10px;
  font-family: "Poppins";
  border-radius: 4px;
  cursor: pointer;
  box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px,rgba(45, 35, 66, 0.3) 0 7px 13px -3px,#d6e7d8 0 -3px 0 inset;
}
div.error {
  padding: 10px;
  background: #ffefef;
  border: 1px solid var(--error);
  color: var(--error);
  border-radius: 4px;
  margin: 20px 0;
}
div.success {
  padding: 10px;
  background: #effff4;
  border: 1px solid var(--success);
  color: var(--success);
  border-radius: 4px;
  margin: 20px 0;
}
input.error {
  border: 1px solid var(--success);
}

/* navbar */
nav {
  display: flex;
  align-items: center;
}
nav a {
  margin-left: 10px;
}
nav button {
  background-color: var(--primary);
  box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px,rgba(45, 35, 66, 0.3) 0 7px 13px -3px,#D6D6E7 0 -3px 0 inset;
  color: white;
  border: 2px solid var(--primary);
  padding: 6px 10px;
  border-radius: 4px;
  font-family: "Poppins";
  cursor: pointer;
  font-size: 1em;
}

/* auth forms */
form.createuser, form.login {
  max-width: 400px;
  margin: 40px auto;
  padding: 20px;
  background: #fff;
  border-radius: 4px;
}
.username_holder{
  margin: 5px,5px,5px,5px;
  padding-right : 25px;
}
.height{
  height: 20px;
}
.json_file {  
  /* width: 300px;
  height: 400px; */
  font-family: monaco,consolas,"courier new",monospace;
  border: 2px solid ;
  border-radius: 25px;
  border-color: #73AD21;
  font-size: 1em;
  min-height: 3em;
  overflow: auto;
  padding: 1em;
  display: inline-block;


}

/*----------Home Page ------------*/
.org-list{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.org-card{
  border-radius: 20px;
  padding: 20px;
  margin: 20px;
  width: 200px;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;

  background: #e0e0e0;
  box-shadow: var(--neumorphism);
  /* background: linear-gradient(145deg, #f0f0f0, #cacaca);
  box-shadow:  20px 20px 60px #bebebe,
               -20px -20px 60px #ffffff; */
}


/*--------Admin---------*/
.org-container{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 50px;
}
.org-table{
  /* width:70%; */
  border-spacing: 20px;
  background: #e0e0e0;
  box-shadow: var(--neumorphism);
  border-radius: 0.7rem;
  padding:10px
  
}
.org-table th{
  text-align: left;

}
.org-table tr{


}
.org-name {
  text-decoration: none;
  color: var(--secondary);
  position: relative; /* Add position relative for absolute positioning of pseudo-element */
}

.org-name::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -3px;
  width: 0;
  height: 2px;
  background-color: var(--secondary);
  transition: width 0.3s ease; /* Add transition for smooth animation */
}

.org-name:hover::after {
  width: 100%;
}


.loader {
  margin: 20px auto;
  width: 50px;
  padding: 8px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: var(--primary);
  --_m: conic-gradient(#0000 10%, #000), linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
  mask: var(--_m);
  -webkit-mask-composite: source-out;
  mask-composite: subtract;
  animation: l3 1s infinite linear;
}





.org-table button{
  height: 30px;
  margin: 5px;
}
button {
  align-items: center;
  margin: 5px;
  appearance: none;
  background-color: #ebeaea;
  border-radius: 4px;
  border-width: 0;
  box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px,rgba(45, 35, 66, 0.3) 0 7px 13px -3px,#D6D6E7 0 -3px 0 inset;
  box-sizing: border-box;
  color: #36395A;
  cursor: pointer;
  display: inline-flex;
  font-family: "JetBrains Mono",monospace;

  justify-content: center;
  line-height: 1;
  list-style: none;
  overflow: hidden;
  padding-left: 10px;
  padding-right: 10px;
  position: relative;
  text-align: left;
  text-decoration: none;
  transition: box-shadow .15s,transform .15s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  will-change: box-shadow,transform;
  font-size: 18px;
  
}

button:focus {
  box-shadow: #D6D6E7 0 0 0 1.5px inset, rgba(45, 35, 66, 0.4) 0 2px 4px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #D6D6E7 0 -3px 0 inset;
}

button:hover {
  box-shadow: rgba(45, 35, 66, 0.4) 0 4px 8px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #D6D6E7 0 -3px 0 inset;
  transform: translateY(-2px);
}

button:active {
  box-shadow: #D6D6E7 0 3px 7px inset;
  transform: translateY(2px);
}

/*--------Org form --------------*/
.org-form{
  margin: 0 15px;
  padding: 20px;
  width: fit-content;
  border-radius: 0.7rem;
  box-shadow:var(--neumorphism)
}
#org-submit-btn{
  margin:auto;
  display: block;
}
#org-select-user{
  margin: 10px auto;
  
  height: 25px;
  border-radius: 5px;
}



.googleClientConfigForm{
 width: clamp(200px, 40%, 400px);
 margin: 30px auto;
 padding:20px;
   border-radius: 0.7rem;
   box-shadow: var(--neumorphism)
}

/*---------spinner------------*/
.lds-ring {
  margin: 11px auto;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #818181;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #9e9e9e transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


/* Mobile-specific styles */
@media only screen and (max-width: 767px) {
  *{
    font-size: small !important;
  }
  .org-container{
    flex-direction: column-reverse;
 
    align-content: center;
    align-items: center;
  }
  .org-form{
    margin-bottom: 20px;
  }
  .org-table{
    border-spacing:5px;
        padding:0;
  }
}

